.menuBanner{
    height: 100%;
    width: 100%;
}
.menuBanner-img{
    background-image: url("https://images.mydigimenu.com/media/222/znWIZVzfPT-07102117107910552.jpg");
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.menuBanner-menuFilter{
    display: flex;
    flex-direction: row;
}
.menuFilter-item{
    height: 25px;
    margin: 10px 5px;
    min-width: 95px;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(111, 78, 55);
    /* background-color: rgb(111, 78, 55);   */
}
.menuFilter-item div{
    font-size: 12px;
    color: rgb(255, 255, 255);
}
.menuBanner-itemFilter{
    display: flex;
    flex-direction: row;
    height: 130px;
    margin-bottom: 5px;
}
.itemFilter-item{
    width: 120px;
    height: 120px;
    margin: 5px;
    align-items: center;
}
.item-img{
    height: 80px;
}
.item-img img{
    width: 70%;
    height: 100%;
    
}
.item-text{
    height: 50px;
    font-size: 12px;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: bold;
    color: black;
}
.menuBanner-poster{
    height: 145px;
    margin-bottom: 5px;
}
.menuBanner-poster div{
    height: 130px;
    width: 100%;
}
.posterImg{
    width: 100%;
    height: 100%;
    background-image: url("https://images.mydigimenu.com/media/222/222-170922150904.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
}
.menuBanner-items-container{
    height: 80vh;
    overflow: auto;
    padding-bottom: 45px;
    flex-direction: column;
}
.menuBanner-items-container-deactivate{
    height: 68vh;
    overflow: auto;
    padding-bottom: 45px;
    flex-direction: column;
}
.itemContainer-menuName{
    width: 100%;
    font-size: 23px;
    align-self: center;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: white;
}
.itemContainer-itemDetails{
        margin-bottom: 15px;
    height: 120px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2.5px;
    border-radius: 7px;
    background-color: rgb(0, 0, 0);
}
.itemDetails-img{
    width: 25%;
    height: 100%;
}
.itemDetails-img img{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 100%;
}
.itemDetails-desc{
    width: 70%;
    height: 100%;
    flex-direction: column;
}
.desc-topTag{
    display: flex;
    justify-content: center;
    background-color: rgb(111, 78, 55);
    border-radius: 3px;
    font-size: 9px;
    height: 18px;
    margin-left: 5px;
    margin-top: 7px;
    padding: 0px 6px;
    color: rgb(255, 255, 255);
}
.desc-topTag-text{
    display: flex;
    align-items: center;
    font-size: 11px;
    text-align: center; 
    margin-bottom: 2px;
    color: rgb(255, 255, 255);
}
.itemDetails-desc-itemName{
    margin-left: 9px;
    margin-top: 4px;
    color: rgb(255, 255, 255);
    font-weight: 460;
}
.itemDetails-desc-badge{
    height: 20px;
    margin-right: 5px;
    width: 20px
}
.itemDetails-desc-itemDesc{
    font-size: 9px;
    margin-left: 10px;
    margin-top: 2px;
    color: rgb(255, 255, 255);
}
.itemDetails-desc-itemPrice{
    margin-left: 9px;
    margin-bottom: 9px;
    font-weight: 500;
    color: rgb(255, 255, 255);
}
.itemDetails-button{
    align-items: center;
    margin-bottom: 7px;
    margin-right: 9px;
}
.itemDetails-button-btn{
    background-color: rgb(111, 78, 55);
    width: 63px;
    margin-right: 2px;
    margin-bottom: 2px;
    height: 23px;
    border-radius: 4px;
    cursor: pointer;
}
.itemDetails-button-desc{
    font-size: 8px;
}
.menu-cart{
    position: fixed;
    left: 0;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .menuFilter-item div{
        font-size: 10px;
    }
    .itemFilter-item .item-text{
        font-size: 11px;
    }
    .itemDetails-desc-itemName span{
        font-size: 10px;
    }
    .itemDetails-desc-itemPrice{
        font-size: 12px;
    }
    .itemDetails-button-btn{
        width: 48px;
        height: 18px;
    }
    .itemDetails-button-btn button {
        font-size: 13px;
    }
    .itemDetails-button-desc{
        font-size: 5px;
    }
    .itemDetails-img{
        width: 60%;
    }
}
@media only screen and (max-width: 1200px){
    .desc-topTag-text{
        font-size: 6px;
    }
    .itemDetails-desc-itemDesc{
        font-size: 7px;
    }
}
@media only screen and (min-width: 1023px) and (max-width: 1200px){
    .itemDetails-desc-itemName span{
        font-size: 12px;
    }
    .itemDetails-button-desc{
        font-size: 6px;
    }
}
.cart-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    border-radius: 10px;
    background-color: rgb(111, 78, 55);
    box-shadow: rgb(0 0 0 / 30%) 0px 2.5px;
    color: white;
}
