.reviewOrder {
  height: 100%;
  margin-left: 20px;
}
.reviewOrder-detail {
  height: 100%;
  /* margin-bottom: 70px; */
  width: 96%;
  overflow: auto;
}
.reviewOrder-servingTo {
  margin-top: 80px;
  margin-bottom: 20px;
}
.reviewOrder-servingTo-title {
  font-size: 20px;
  font-weight: bold;
  margin: 15px 0;
}
.reviewOrder-servingTo-cafe {
  width: 98%;
  display: flex;
  flex-direction: row;
  margin: 7px;
  padding: 8px;
  border-radius: 8px;
  border-width: 0.5px;
}
.reviewOrder-servingTo-cafe-icon {
  width: 30px;
  height: 30px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: rgb(228, 230, 235);
}
.reviewOrder-servingTo-cafe-icon i {
  font-size: 20px;
  color: black;
}
.reviewOrder-servingTo-cafe-text {
  font-size: 14px;
  margin-left: 5px;
}
.reviewOrder-servingDate-btn {
  background-color: rgb(111, 78, 55);
  border: 1px solid grey;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  padding: 5px;
}
.reviewOrder-instruction {
  width: auto !important;
}
.reviewOrder-tips {
  margin: 20px 5px;
}
.reviewOrder-tips-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.reviewOrder-tips-text {
  margin-top: 5px;
}
.reviewOrder-tips-text input {
  border: 1px solid grey;
  color: rgb(128, 128, 128);
  font-size: 17px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  padding: 8px;
}
.reviewOrder-tips-per-item {
  color: rgb(111, 78, 55);
  font-weight: bold;
  border-color: rgb(111, 78, 55);
  margin-right: 15px;
  padding: 6px 0;
  width: 50px;
  height: 35px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  border-width: 1px;
}
.reviewOrder-totalDetail {
  padding: 8px;
  margin: 7px;
  font-size: 14px;
  border-width: 0.5px;
  border-radius: 8px;
  border-color: rgb(211, 211, 211);
}
.reviewOrder-totalDetail-total {
  font-size: 16px;
  font-weight: bold;
}
.reviewOrder-placeOrder{
  height: 70px;
  background-color: rgb(255, 255, 255);
}
.reviewOrder-placeorder-payment{
  height: 50px;
  width: 90%;
  padding: 8px;
}
.reviewOrder-placeorder-payment-icon{
  width: 30px;
  height: 30px;
  margin-right: 6px;
  background-color: rgb(228, 230, 235);
  border-radius: 50%;
}
.reviewOrder-placeorder-payment-icon i{
  font-size: 12px;
  color: black;
}
.reviewOrder-placeorder-payment-text{
  font-size: 10px;
  cursor: pointer;
  font-weight: bold;
}
.reviewOrder-placeorder-btn{
  width: 100%;
  height: 100%;
}
.reviewOrder-placeorder-btnDiv{
    background-color: rgb(111, 78, 55);
    border-color: rgb(63, 81, 181);
    padding: 6px;
    width: 96%;
    height: 50px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    color: rgb(255, 255, 255);
}
.reviewOrder-placeorder-btnDiv i{
  font-size: 16px !important;
}

/* Modal */
.paymentModeModal-head{
  border-bottom-color: rgba(12, 10, 10, 0.2);
  border-bottom-width: 1px;
  width: 100%;
  height: 40px;
}
.paymentModeModal-head-title{
  font-size: 14px;
    margin-left: 8px;
}
.paymentModeModal-head-close, .paymentModeModal-main-card-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 6px;
  background-color: rgb(228, 230, 235);
  border-radius: 50%;
}
.paymentModeModal-head-close i {
  color: red;
  font-size: 20px;
}
.paymentModeModal-main-card{
  height: 45px;
  margin: 7px;
  border-width: 0.5px;
  border-color: rgb(211, 211, 211);
  border-radius: 8px;
  cursor: pointer;
}
.paymentModeModal-main-card-icon i{
  color: rgb(93, 102, 112);
    font-size: 13px;
    font-weight: 900;
}

/* Media Query */
@media only screen and (max-width: 1200px){ 
  .reviewOrder-servingTo-title{
    font-size: 16px;
  }
  .reviewOrder-servingTo-cafe-text{
    font-size: 10px;
  }
  .reviewOrder-servingTo-cafe-icon{
    width: 20px;
    height: 20px;
  }
  .reviewOrder-servingTo-cafe-icon i{
    font-size: 12px;
  }
  .itemDetail-instruction-title{
    font-size: 16px;
  }
  .reviewOrder-tips-title{
    font-size: 16px;
  }
  .reviewOrder-totalDetail{
    font-size: 12px;
  }
  .reviewOrder-totalDetail-total{
    font-size: 14px;
  }
  .reviewOrder-placeorder-btnDiv{
    font-size: 9px;
    height: 40px;
  }
  .reviewOrder-placeorder-payment-text{
    font-size: 9px;
  }
  .reviewOrder-placeorder-payment-icon{
    height: 20px;
    width: 20px;
  }
  .reviewOrder-placeorder-payment-icon i{
    font-size: 8px;
  }
}