.itemDetail{
    height: 100%;
}
.itemDetail-detail{
    overflow: auto;
}
.closeBtn{
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    margin: 5px;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgb(111, 78, 55);
    border: 1px solid rgb(111, 78, 55);
    box-shadow: rgb(0 0 0 / 30%) 0px 2.5px;
}
.itemDetail-container{
    position: relative;
    margin: 10px 0px;
}
.itemDetail-media{
    width: 100%;
    height: 250px;
}
.itemDetail-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://images.mydigimenu.com/media/hjtNsdOtSb/hjtNsdOtSb-05122111129050286.JPG");
}
.itemDetail-favorite{
    position: absolute;
    right: 8px;
    cursor: pointer;
}
.itemDetail-favorite i{
    color: rgb(111, 78, 55);
    font-size: 25px;
}
.fa-clock{
    color: black;
    font-size: 20px;
    margin: 5px;
}
.itemDetail-description-name span{
    font-size: 20px;
}
.itemDetail-description-category-items{
    margin-bottom: 10px;
    margin-right: 4px;
    cursor: pointer;
    border-radius: 4px;
    background-color: rgb(111, 78, 55);
    border: 1px solid rgb(111, 78, 55);
    padding: 3px;
}
.itemDetail-description-category-icon{
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
}
.itemDetail-description-category-name{
    font-size: 10px;
    margin-left: 3px;
    color: rgb(255, 255, 255);
}
.itemDetail-description-desc span{
    font-size: 12px;
    margin: 3px;
}
.itemDetail-options-title{
    font-weight: 500;
    margin-bottom: 5px;
    margin-left: 25px;
    margin-top: 8px;
    font-size: 14px;
}
.itemDetail-options-items{
    border-bottom: 1px solid grey;
    margin: 15px 0;
    width: 90%;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
}
.itemDetail-options-name{
    margin: 10px 0;
    width: 65%;
    color: rgb(111, 78, 55);
    margin-left: 6px;
    margin-top: 3px;
}
.itemDetail-options-cal-prize{
    color: rgb(111, 78, 55);
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.itemDetail-extras-title{
    background-color: rgb(253, 253, 253);
    font-weight: 500;
}
.itemDetail-extras-sides{
    display: flex;
    flex-direction: column;
    width: 90%;
    font-size: 14px;
    margin: 6px 0px;
}
.itemDetail-extras-sides-title{
    margin-left: 8px;
    margin-bottom: 12px;
}
.itemDetail-extras-item{
    margin-bottom: 6px;
}
.itemDetail-extras-item-left span{
    color: rgb(111, 78, 55);
    margin: 0 8px;
}
.itemDetail-extras-item-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(111, 78, 55);
    height: 25px;
    margin: 10px 15px 10px 10px;
    width: 25px;
    border-radius: 8px;
    cursor: pointer;
}
.itemDetail-extras-item-btn i {
    font-size: 15px !important;
    color: rgb(255, 255, 255);
}
.itemDetail-extras-item-right{
    color: rgb(111, 78, 55);
}


.itemDetail-instruction{
    margin: 20px 5px;
    width: 90%;
}
.itemDetail-instruction-title{
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}
.itemDetail-instruction textarea{
    width: 100%;
    border: 1px solid rgb(217, 213, 220);
    height: 80px;
    font-size: 15px;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 5px;
    vertical-align: top;
    border-radius: 5px;
}
.itemDetail-cartSection{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    /* height: 70px;
    width: 100%; */
    background-color: rgb(253, 253, 253);
    box-shadow: rgb(0 0 0 / 30%) 0px 2.5px 4p
}
.itemDetail-cartSection-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(111, 78, 55);
    margin: 15px;
    padding: 12px;
    border-radius: 7px;
    cursor: pointer;
}
.itemDetail-cartSection-btn i{
    font-size: 15px !important; 
    color: rgb(111, 78, 55) !important;
}
.itemDetail-cartSection-qty{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
}
.itemDetail-cartSection-button{
    background-color: rgb(111, 78, 55);
    border-color: rgb(63, 81, 181);
    height: 45px;
    cursor: pointer;
    border-radius: 8px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

@media only screen and (max-width: 1200px){ 
    .itemDetail-cartSection-btn{
        margin: 10px;
        padding: 8px;
    }
    .itemDetail-options-items{
        font-size: 12px;
    }
    .itemDetail-extras-sides{
        font-size: 12px;
    }
    .itemDetail-description-desc span{
        font-size: 10px;
    }
    .itemDetails-desc-badge{
        height: 15px;
        width: 15px;
    }
    .itemDetail-description-name span{
        font-size: 15px;
    }
    .fa-clock{
        font-size: 15px;
    }
    .itemDetail-description-time{
        font-size: 15px;
    }
    .itemDetail-description-category-icon i{
        font-size: 15px;
    }
}