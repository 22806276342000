/* header styles */
.header {
  display: block;
  text-align: center;
  font-size: 30px;
  margin: 10px 0;
}
.productContainer {
  margin: 10px;
  width: -webkit-fill-available;
}
.number {
  display: flex;
}
input {
  width: 5em;
}
.cartitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-bottom: 20px;
}

.cartItemImg {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.cartItemDetail {
  display: flex;
  flex: 1;
  padding: 0 20px;
  flex-direction: column;
}

/* Home Page */

.home {
  display: flex;
  justify-content: space-between;
}

.filters {
  background-color: #343a40;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 10px;
}

.filters > span {
  padding-bottom: 20px;
}

.title {
  font-size: 30px;
}

.products {
  width: 30%;
  margin: 10px;
}

.summary {
  width: 30%;
}
.kitchens {
  margin: 1em;
}
/* Media Queries */
@media (max-width: 771px) {
  .filters {
    width: 100%;
    padding: 10px;
    margin: 5px;
  }

  .filters > span {
    font-size: 10px;
  }

  .title {
    font-size: 18px !important;
  }

  .search {
    display: none !important;
  }

  .products {
    width: 100%;
  }
}
.checkOutButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
#checkout {
  display: table;
  margin: 0 auto;
  width: 50%;
}

#checkout-main {
  width: 100%;
}
.checkout-div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
  font-weight: 600;
}
.price {
  font-size: xx-large;
  color: grey;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}
.spiner {
  display: block !important;
  margin: auto !important;
}

/* Home banner */
.homeBannerText {
  color: rgb(165, 42, 42);
  font-size: 30px;
  font-weight: bold;
  text-decoration-line: underline;
}
.homeBanner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.homeBannerImg {
  background-image: url("https://images.mydigimenu.com/media/znWIZVzfPT/znWIZVzfPT-19032015030430926.jpg");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
i {
  color: rgb(255, 255, 255);
  font-size: 22px;
}
.homeBanner-header {
  height: 60px;
  width: 100%;
  flex-direction: row;
}
.fa-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 35px;
  width: 35px;
  margin: 5px;
  border-radius: 10px;
  background-color: rgb(111, 78, 55);
  box-shadow: rgb(0 0 0 / 30%) 0px 2.5px;
}
.fa-angle-left:before {
  content: "\f104";
  box-sizing: border-box;
}
.fa {
  font-weight: 900;
}
.homeBanner-main .divButton {
  border-radius: 100px;
  height: 45px;
  margin-top: 150px;
  width: 170px;
  background-color: rgb(111, 78, 55);
  box-shadow: rgb(0 0 0 / 30%) 0px 2.5px;
}
.divButton .button {
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin: 8px;
  text-align: center;
}
.homeBanner-main-socials {
  margin-top: 25px;
}
.homeBanner-footer {
  justify-content: center;
  align-items: center;
  height: 60px;
  display: flex;
  margin: 20px;
}
.footer-logo {
  height: 40px;
  width: 220px;
}
.footer-logo .logo {
  background-image: url("https://qr.mydigimenu.com/static/media/logo-tans.d5b07524.png");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.modalCloseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  border-color: rgb(0, 0, 0);
  width: 50px;
  height: 50px;
  background-color: rgb(255, 255, 255);
  border-width: 1px;
  border-radius: 7px;
}
.modalCloseBtn i {
  color: rgb(111, 78, 55);
  font-size: 30px;
}
.ReactModal__Overlay,
.ReactModal__Overlay--after-open{
  background-color: transparent !important;
}
/* .ReactModal__Overlay,
.ReactModal__Overlay--after-open {
  background-color: transparent !important;
  height: 85%;
  width: 80%;
  inset: 0px;
  position: absolute;
  align-items: center;
  top: 10% !important;
  left: 50% !important;
  right: auto;
  bottom: auto;
  transform: translate(-50%, 0);
} */

.footer{
  background-color: #343a40!important;
}