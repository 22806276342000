.cart-title{
    padding-top: 15px;
    font-size: 18px;
    font-weight: bold;
}
.cartItem{
    overflow: auto;
    /* height: 70.8vh; */
    /* margin-top: 50px; */
    /* margin-bottom: 80px; */
    width: 96%;
}
.cartItem-Img{
    height: 90px;
    width: 90px;
}
.cartItem-Img-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.cartItem-details{
    font-size: 14px;
    font-weight: 500;
}
.cartItem-right-btn{
    background-color: rgb(111, 78, 55);
    width: 63px;
    height: 23px;
    margin: 2px;
    color: rgb(255, 255, 255);
    border-radius: 4px;
}
.cartItem-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    cursor: pointer;
}
.cartItem-btn i{
    font-size: 12px  !important;
}
.cartItem-delete-btn{
    margin: 0 10px;
    cursor: pointer;
}
.cartItem-delete-btn i{
    color: rgb(255, 0, 0) !important;
    font-size: 18px !important;
}
.cartItem-tax-text{
    padding: 5px;
    text-align: center;
    font-size: 14px;
    font-family: SecondaryFont;
}
.cartItem-reviewItem{
    background-color: rgb(111, 78, 55);
    color: rgb(255, 255, 255);
    height: 50px;
    cursor: pointer;
}
.cartItem-reviewItem-left{
    margin-left: 17px;
}
.cartItem-reviewItem-left-qty{
    font-size: 10px;
    margin-bottom: 3px;
}
.cartItem-reviewItem-left-price{
    font-size: 16px;
    font-weight: 500;
}
.cartItem-reviewItem-right{
    margin-right: 17px;
    font-size: 18px;
    font-weight: bold;
}
.cartItem-reviewItem-right i{
    margin-left: 5px;
}

@media only screen and (max-width: 1200px){ 
    /* .cartItem{
        margin-top: 25px;
    } */
    .cartItem-details{
        font-size: 10px;
    }
    .cartItem-right-btn{
        width: 50px;
        height: 20px;
    }
    .cartItem-btn i{
        font-size: 10px;
    }
    .cartItem-qty{
        font-size: 12px;
    }
    .cartItem-reviewItem-left-qty{
        font-size: 8px;
    }
    .cartItem-reviewItem-left-price{
        font-size: 12px;
    }
    .cartItem-reviewItem-right{
        font-size: 15px;
    }
    .cartItem-tax-text{
        font-size: 10px;
    }
}